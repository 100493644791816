// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'gb',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '40cae9a60bf1e6065e1e122670fcd03eb5740cfc',
    releaseTagRef: '54daf2e',
    releaseTimestamp: '1724739184',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '9173edc6287c7687c01a9d1819b5baae',
      branch: 'key_live_jnSH5auzAJOEvAAl5zeLulgirzpwxiQv',
      braze: '60816b26-593a-4bfd-bb91-fc5ae17967e8',
      brazeCluster: 'sdk.fra-01.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyDOtGSKPthz3am_QTmuavFu42QiIcVORm0',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '',
      launchDarkly: '6446874675a6a5129f32baa8',
      launchDarklyMobileKey: 'mob-2adb55dc-f3cd-48f4-a3cf-41d007fc9dac',
      mParticle: 'us1-c9319dfe06acec42aa53b4aab590833f',
      radar: '',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: 'merchant.com.bk.native.tillster.prod',
      migrationMerchantId: '',
      paymentsNetworks: ['MasterCard','Visa'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: ['burgerkinguk.test-app.link','burgerkinguk-alternate.test-app.link','burgerkinguk.app.link','burgerkinguk-alternate.app.link']
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '5',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: '8389ed4f1d54',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'firstdata',
      marketPaymentsGatewayMerchantId: '456031998994',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '456031998994',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['MASTERCARD','VISA'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: 'https://privacyportal-de.onetrust.com/webform/67b80636-41d6-4b37-aea3-717da0a9526f/0eaa8dd8-6d19-4e7a-b791-12acc2614554',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-03.braze.com/api/v3',
      firstData: 'https://api.payeezy.com',
      firstDataTh: 'https://api.payeezy.com',
      webBaseUrl: '',
      thDigital: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: '',
    addressFormat: 'number_name',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: 'https://apps.apple.com/us/app/burger-king-app-food-drink/id1062524641',
    androidDeepLink: 'https://play.google.com/store/apps/details?id=com.emn8.mobilem8.nativeapp.bkuk',
    cookieBanners: {
      cookieBanner:'customCookieBanner',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };